.cft-component-president,
.cft-component-primary {
  @apply border-grey-200 bg-president-500 text-president-50;
}

.cft-component-president.cft-variant-outlined,
.cft-component-primary.cft-variant-outlined {
  @apply border-president-500 bg-white text-president-500;
}

.cft-component-grey {
  @apply border-grey-400 bg-grey-900 text-grey-50;
}

.cft-component-grey.cft-variant-outlined {
  @apply border-grey-900 bg-white text-grey-900;
}

.cft-component-grey-light {
  @apply border-grey-100 bg-grey-300 text-grey-700;
}

.cft-component-grey-light.cft-variant-outlined {
  @apply border-grey-700 bg-white text-grey-700;
}

.cft-component-grey-lighter {
  @apply border-white bg-grey-100 text-grey-700;
}

.cft-component-grey-lighter.cft-variant-outlined {
  @apply border-grey-500 bg-white text-grey-500;
}

.cft-component-president-light {
  @apply border-president-100 bg-president-200 text-president-500;
}

.cft-component-president-light.cft-variant-outlined {
  @apply border-president-200 bg-white text-president-200;
}

.cft-component-president-lighter {
  @apply border-white bg-president-50 text-president-500;
}

.cft-component-president-lighter.cft-variant-outlined {
  @apply border-president-50 bg-white text-president-50;
}

.cft-component-gold {
  @apply border-gold-200 bg-gold-500 text-president-50;
}

.cft-component-gold.cft-variant-outlined {
  @apply border-gold-500 bg-white text-gold-500;
}

.cft-component-secondary,
.cft-component-white {
  @apply border border-grey-300 bg-white text-president-500;
}

.cft-component-vermilion {
  @apply border-vermillon-200 bg-vermillon-500 text-white;
}

.cft-component-vermilion.cft-variant-outlined {
  @apply border-vermillon-500 bg-white text-vermillon-500;
}

.cft-component-vermilion-dark {
  @apply border-vermillon-300 bg-vermillon-700 text-white;
}

.cft-component-vermilion-dark.cft-variant-outlined {
  @apply border-vermillon-700 bg-white text-vermillon-700;
}

.cft-component-purple {
  @apply border-purple-200 bg-purple-500 text-president-50;
}

.cft-component-purple.cft-variant-outlined {
  @apply border-purple-500 bg-white text-purple-500;
}

.cft-component-grape {
  @apply border-purple-200 bg-purple-500 text-president-50;
}

.cft-component-grape.cft-variant-outlined {
  @apply border-purple-500 bg-white text-purple-500;
}

.cft-component-red {
  @apply border-red-200 bg-red-500 text-white;
}

.cft-component-red.cft-variant-outlined {
  @apply border-red-500 bg-white text-red-500;
}

.cft-component-red-light {
  @apply border-red-200 bg-red-400 text-white;
}

.cft-component-red-light.cft-variant-outlined {
  @apply border-red-400 bg-white text-red-400;
}

.cft-component-red-dark {
  @apply border-red-400 bg-red-800 text-white;
}

.cft-component-red-dark.cft-variant-outlined {
  @apply border-red-800 bg-white text-red-800;
}

.cft-component-red-darker {
  @apply border-red-400 bg-red-950 text-white;
}

.cft-component-red-darker.cft-variant-outlined {
  @apply border-red-950 bg-white text-red-950;
}

.cft-component-orange {
  @apply border-orange-200 bg-orange-500 text-white;
}

.cft-component-orange.cft-variant-outlined {
  @apply border-orange-500 bg-white text-orange-500;
}

.cft-component-orange-light {
  @apply border-orange-100 bg-orange-300 text-white;
}

.cft-component-orange-light.cft-variant-outlined {
  @apply border-orange-300 bg-white text-orange-300;
}

.cft-component-orange-dark {
  @apply border-orange-300 bg-orange-600 text-white;
}

.cft-component-orange-dark.cft-variant-outlined {
  @apply border-orange-600 bg-white text-orange-600;
}

.cft-component-orange-darker {
  @apply border-orange-300 bg-orange-700 text-white;
}

.cft-component-orange-darker.cft-variant-outlined {
  @apply border-orange-700 bg-white text-orange-700;
}

.cft-component-green {
  @apply border-green-200 bg-green-500 text-president-50;
}

.cft-component-green.cft-variant-outlined {
  @apply border-green-500 bg-white text-green-500;
}

.cft-component-green-light {
  @apply border-green-200 bg-green-400 text-white;
}

.cft-component-green-light.cft-variant-outlined {
  @apply border-green-400 bg-white text-green-400;
}

.cft-component-green-dark {
  @apply border-green-300 bg-green-700 text-president-50;
}

.cft-component-green-dark.cft-variant-outlined {
  @apply border-green-700 bg-white text-green-700;
}

.cft-component-blue {
  @apply border-blue-200 bg-blue-500 text-president-50;
}

.cft-component-blue.cft-variant-outlined {
  @apply border-blue-500 bg-white text-blue-500;
}

.cft-component-petrol {
  @apply border-petrol-200 bg-petrol-400 text-president-50;
}

.cft-component-petrol.cft-variant-outlined {
  @apply border-petrol-500 bg-white text-petrol-500;
}

.cft-component-yellow {
  @apply border-yellow-200 bg-yellow-500 text-president-500;
}

.cft-component-yellow.cft-variant-outlined {
  @apply border-yellow-500 bg-white text-yellow-500;
}

.cft-component-rivergreen {
  @apply border-rivergreen-200 bg-rivergreen-500 text-white;
}

.cft-component-rivergreen.cft-variant-outlined {
  @apply border-rivergreen-500 bg-white text-rivergreen-500;
}

.grid-teaser-cards,
.hero-grid-teaser-cards {
  @apply grid grid-cols-1 md:-mx-sm md:grid-cols-2 md:gap-y-lg xl:grid-cols-3;
}

.grid-2-cols-teaser-cards {
  @apply grid grid-cols-1 md:grid-cols-2 md:gap-y-lg;
  @apply md:-mx-1.5;
}

.grid-4-cols-teaser-cards {
  @apply grid grid-cols-1 md:grid-cols-2 md:gap-y-lg lg:grid-cols-4;
  @apply md:-mx-1.5;
}

.cft-dialog-backdrop {
  @apply bg-black !opacity-50;
}

.cft-buttons-row {
  @apply inline-flex shrink-0 gap-[1px] overflow-hidden rounded-md border border-grey-200 bg-grey-200;
}
